import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/login.vue')
  },
  {
    path: '/about',
    name: 'About the Group ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About the Group.vue')
  },
  {
    path: '/publicNews',
    name: 'publicNews ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PublicNews.vue')
  },
  {
    path: '/another',
    name: 'Group News',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Group News.vue')
  },
  {
    path: '/aboutProduct',
    name: 'aboutProduct',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutProduct.vue')
  },
  {
    path: '/other',
    name: 'Contact Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact Us.vue')
  },
  {
    path: '/join',
    name: 'Join Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Join Us.vue')
  },
  {
    path: '/xiangqing',
    name: 'News Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News Details.vue')
  },
  {
    path: '/zhaopin',
    name: 'zhaopinxiangqing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zhaopinxiangqing.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    redirect: 'admin/gscy',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/index.vue'),
    children: [
      {
        path: 'gscy',
        name: 'gscy',
        meta: {
          title: '公司产业'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/baseInfo/gscy.vue')
      },
      {
        path: 'cpjs',
        name: 'cpjs',
        title: '合作企业',
        meta: {
          title: '合作企业'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/baseInfo/cpjs.vue')
      },
      {
        path: 'zcjy',
        name: 'zcjy',
        title: '总裁寄语',
        meta: {
          title: '总裁寄语'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/baseInfo/zcjy.vue')
      },
      {
        path: 'gyqy',
        name: 'gyqy',
        title: '关于企业',
        meta: {
          title: '关于企业'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/baseInfo/gyqy.vue')
      },
      {
        path: 'news',
        name: 'news',
        title: '资讯管理',
        meta: {
          title: '资讯管理'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/news/index.vue')
      },
      {
        path: 'editNews',
        name: 'editNews',
        title: '编辑资讯',
        meta: {
          title: '编辑资讯'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/news/addNew.vue')
      },
      {
        path: 'invite',
        name: 'invite',
        title: '招聘管理',
        meta: {
          title: '招聘管理'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/invite/index.vue')
      },
      {
        path: 'editInvite',
        name: 'editInvite',
        title: '编辑招聘消息',
        meta: {
          title: '编辑招聘消息'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/invite/addInvite.vue')
      },
      {
        path: 'profiles',
        name: 'profiles',
        title: '信息列表',
        meta: {
          title: '信息列表'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/profile/index.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
