<template>
  <div class="div-nav1">
    <img src="../../assets/homepage/logo(2).png" class="imge-logo" />
    <div class="nav1">
      <ul class="nav_ul">
        <li>首页</li>
        <li>
          <router-link to="/about">公司概况</router-link>
        </li>
        <li><router-link to="/publicNews">公开信息</router-link></li>
        <li><router-link to="/aboutProduct">产品展示</router-link></li>
        <li><router-link to="/another">公司新闻</router-link></li>
        <li><router-link to="/join">招聘信息</router-link></li>
        <li><router-link to="/other">联系我们</router-link></li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.ddd:hover .dropdown_content {
  display: block;
  z-index: 999;
}
.divsss p {
  text-align: center;
  line-height: 40px;
  color: #000;
}
/* .divsss {
  width: 140px;
  height: 48px;
  background: #ffffff;

} */
.dropdown_content {
  display: none;
  position: absolute;
  width: 140px;
  height: 306px;
  background: #ffffff;
  top: 71px;
  left: 192px;
}

.nav2 {
  position: absolute;
  top: 90px;
  left: 176px;
  margin: 0 auto;
  padding-left: 63px;
  width: 140px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 3px 11px -2px rgba(0, 0, 0, 0.07);
  display: none;
}
.nav2 li {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 20px;
  margin-top: 28px;
  /* letter-spacing:1px */
}
.nav2 li {
  list-style-type: none;
  width: 300px;
  height: 300px;
  /* margin-top: -90px; */
  /* border: 1px solid red; */
}
.imge-logo {
  width: 230px;
  height: 70px;
  position: absolute;
  top: 13px;
  left: 80px;
}
.div-nav1 {
  position: absolute;
  top: 40px;
  left: 240px;
  margin: 0 auto;
  padding-left: 63px;
  width: 1440px;
  height: 101px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 51px;
}
.nav1 {
  position: absolute;
  left: 550px;
  top: 8px;
}
.nav_ul li {
  list-style-type: none;
  color: black;
  display: inline-block;
  padding: 18px;
  text-decoration: none;
  font-weight: 500px;
}
.nav1 ul li a {
  margin: 2px;
  display: inline-block;
  color: black;
  font-family: "Microsoft Yahei";
  font-size: 16px;
  text-decoration: none;
}
.nav_ul li a:hover {
  color: #6bc30d;
  /* display: inline-block; */
}
</style>
<script>
export default {
  name: "Nav",
  // methods: {
  //   mouseOver() {
  //     console.log("触发了");
  //     this.$refs.selectUl.style.display = ' inline-block';
  //   },
  //   // 移出
  //   mouseLeave() {
  //     this.$refs.selectUl.style.display = 'none';

  //   },
  // },
};
</script>
