<template>
  <div class="all">
    <img src="../assets/homepage/bg1.png" class="imge-back" />
    <Nav class="nav_1"></Nav>
    <div class="text1">
      <p class="text1-p">肇庆佳利印染有限公司</p>
      <span class="text1-span1">科技创新引领发展</span>
      <span class="text1-span2">·</span>
      <span class="text1-span3">卓越品质源自印染</span>
    </div>
    <div class="all2">
      <div>
        <p class="text2">最新资讯</p>
        <!-- <ul class="nav">
          <li><a href=""> 集团新闻</a></li>
          <li><a href=""> 媒体报道</a></li>
          <li><a href=""> 行业资讯</a></li>
        </ul> -->
      </div>

      <div class="all_left" @click="toDetail(leftNew.id)">
        <!-- <img src="../assets/homepage/little.png" /> -->
        <img :src="displayedImage" />
        <div class="all_left_div">
          <p class="all_left_text1">{{ leftNew.title }}</p>
          <p class="all_left_text2">
            {{ leftNew.createdTime | formatDate }}
          </p>
          <p class="all_left_text3">
            {{ leftNew.content | removeTags }}
          </p>
        </div>
      </div>
      <div class="all_right">
        <div 
          class="all_right_div1"
          v-for="item in ObjectList"
          :key="item.id"
          @click="toDetail(item.id)"
         >
          <p class="all_right_text1" >{{ item.title }}</p>
          <p class="all_right_text2">{{ item.createdTime | formatDate }}</p>
          <p class="all_right_text3">{{ item.content | removeTags }}</p>
        </div>
      </div>
      <div class="all3">
        <div class="all3_nav">
          <div class="all3_nav_div1">公司产业</div>
          <div class="all3_nav_div2"></div>
        </div>
        <div class="all3_div1">
          <div
            v-for="(item, index) in imgList"
            :key="index"
            class="all3_div1_div"
          >
            <img :src="item.img1" class="all3_img1" />
            <img :src="item.img2" class="all3_div1_img1" />
            <p class="all3_div1_p1">{{ item.title }}</p>
            <p class="all3_div1_p2">{{ item.nav }}</p>
          </div>
        </div>
        <div class="all3_div2">
          <img :src="rightImg.img1" class="all3_img2" />
          <img :src="rightImg.img2" class="all3_div1_img2" />
          <p class="all3_div2_p1">{{ rightImg.title }}</p>
          <p class="all3_div2_p2">
          </p>
        </div>
      </div>
      <!-- <div class="all4">
        <div class="all4_div1">
          <p class="all4_p1">关于企业</p>
          <p class="all4_p2">
            {{ aboutCompany }}
          </p>
          <img
            src="D:/jiaLiFile/9059fc29-329f-44ac-88556431322aocp27.jpg"
            alt=""
          />
        </div>
        <div class="all4_div2">
          <img src="../assets/homepage/bg3.png" class="all4_img" />
        </div>
      </div> -->
      
      <!-- <div></div> -->
      <Bottom class="nav_2"></Bottom>
    </div>
  </div>
</template>
<script>
import global_msg from "../../global";
import Nav from "./nav1/index.vue";
import Bottom from "./nav1/bottomnav.vue";
export default {
  name: "HelloWorld",
  props: {},
  components: {
    Nav,
    Bottom,
  },
  data() {
    return {
      imgSrc: "",
      aboutCompany:
        "肇庆市佳利印染有限公司是一家提供制造业的公司，总部位于广东省，成立于2003年10月03日。公司主要成员8人，自成立以来，全体员工共同努力，本着诚信为本、积极开拓、优质服务的精神和专业、用心、负责的理念开始了全面发展的新时期，在探索中发展，在创新中前行。在制造业中，我们秉承“分享、协作、进步、未来”的核心文化，用我们的专注和专业为客户带来更多的价值，帮助中国的企业进入“质量”与“品牌”并重的质量时代，业务涵盖生产、销售:纺织品织造、漂染、印花产品等。",
      ObjectList: [
      ],
      leftNew: {
        id:'',
          title: "我国将在全国实施动产和权利担保统一登记—拓融资渠道",
          createdTime: "2021-12-12",
          content:
            "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
          pictureList: [],
      },
      imgList: [
        {
          img1: require("../assets/homepage/矩形(3).png"),
          img2: require("../assets/homepage/矩形.png"),
          title: "生产纺织品",
          nav: " ",
        },
        {
          img1: require("../assets/homepage/矩形(5).png"),
          // img:  require("../assets/homepage/矩形(5).png"),
          img2: require("../assets/homepage/矩形.png"),
          // img2: "../assets/homepage/矩形.png",
          title: "销售纺织品",
          nav: " ",
        },
        {
          img1: require("../assets/homepage/矩形(5).png"),
          // img: "../assets/homepage/矩形(5).png ",
          img2: require("../assets/homepage/矩形.png"),
          // img2: "../assets/homepage/矩形.png",
          title: "纺织品织造",
          nav: " ",
        },
        {
          img1: require("../assets/homepage/矩形(7).png"),
          img2: require("../assets/homepage/矩形.png"),
          // img: "../assets/homepage/矩形(7).png ",
          // img2: "../assets/homepage/矩形.png",
          title: "漂染",
          nav: " ",
        },
      ],
      rightImg: {
        img1: require("../assets/homepage/蒙版(22).png"),
        img2: require("../assets/homepage/矩形.png"),
        // img: "../assets/homepage/矩形(7).png ",
        // img2: "../assets/homepage/矩形.png",
        title: "印花产品",
        nav: " ",
      },
      // arrList: [
      //   { P1: 30, P2: "+", P3: "全国校区" },
      //   { P1: 10, P2: "+", P3: "覆盖城市" },
      //   { P1: 7000, P2: "+", P3: "优秀讲师" },
      //   { P1: 80, P2: "万", P3: "服务学生" },
      // ],
    };
  },
  created() {
    this.onload();
    this.onLoadImage();
    this.onLoadText();
  },
  methods: {
    async onload() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: { pageSize: 4, currentPage: 1, type: 0 },
        });
        if (res.code === 0) {
          this.ObjectList = res.list.slice(1, 5);
          this.leftNew = res.list[0];
          res.list[0].pictureList.forEach((picture) => {
            if (picture.sort === 0) {
              this.imgSrc = global_msg.imgPath + picture.pic;
            }
          });
          if (this.imgSrc === "" || this.imgSrc === undefined) {
            if (res.list[0].pictureList.length > 0) {
              this.imgSrc = global_msg.imgPath + res.list[0].pictureList[0].pic;
            }
          }
        }
        console.log(this.ObjectList);
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadImage() {
      try {
        const { data: res } = await this.$http.get("newController/pictures", {
          params: { type: 4 },
        });
        if (res.code === 0) {
          res.data.forEach((picObj) => {
            switch (picObj.sort) {
              case 1:
                this.imgList[0].img1 = global_msg.imgPath + picObj.pic;
                break;
              case 2:
                this.imgList[1].img1 = global_msg.imgPath + picObj.pic;
                break;
              case 3:
                this.imgList[2].img1 = global_msg.imgPath + picObj.pic;
                break;
              case 4:
                this.imgList[3].img1 = global_msg.imgPath + picObj.pic;
                break;
              case 5:
                this.rightImg.img1 = global_msg.imgPath + picObj.pic;
                break;
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadText() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: { pageSize: 1, currentPage: 1, type: 6 },
        });
        if (res.code === 0) {
          if (res.list[0] !== null) {
            this.aboutCompany = res.list[0].content;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    toDetail(id) {
      this.$router.push({ name: 'News Details', query: { id: id } });
    }
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date
        .toLocaleDateString("zh-CN", options)
        .replace(/\//g, "-")
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$1月$2日");
    },
    removeTags: function (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
  computed: {
    displayedImage() {
      if (this.imgSrc) {
        return this.imgSrc; // 如果 imgSrc 不为空，则返回imgSrc对应的图片路径
      } else {
        return "../assets/homepage/little.png";
      }
    },
  },
};
</script>
<style scoped>
body {
  margin: 0px;
  padding: 0px;
}

.nav_1 {
  z-index: 999;
}

.all {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.imge-back {
  width: 100%;
  height: 800px;
  margin: 0 auto;
}

.text1 {
  width: 1050px;
  height: 793px;
  text-align: center;
  position: absolute;
  top: 0px;
  /* left: 387px; */
  left: 50%;
  transform: translate(-50%);
}

.text1-p {
  width: 100%;
  height: 100px;
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
  line-height: 124px;
  letter-spacing: 1px;
  margin-top: 345px;
  letter-spacing: 10px;
}

.text1-span1 {
  width: 253px;
  height: 27px;
  font-size: 20px;
  font-family: Helvetica;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 7px;
}

.text1-span2 {
  color: #ffffff;
  font-size: 40px;
  margin: 0 18px;
}

.text1-span3 {
  width: 231px;
  height: 27px;
  font-size: 20px;
  font-family: Helvetica;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 7px;
}

.all2 {
  width: 100%;
  height: 801px;
}

.text2 {
  width: 200px;
  height: 50px;
  font-size: 42px;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  margin: 0 360px;
  padding-top: 81px;
}

.nav {
  list-style-type: none;
  margin-top: -50px;
  margin-left: 1151px;
}

.nav li {
  float: left;
  padding: 26px;
  font-size: 20px;
}

a {
  text-decoration: none;
}

.nav li a:hover {
  color: blue;
}

.all_left {
  width: 588px;
  height: 541px;
  background: #f8f8f8;
  margin-left: 360px;
  display: inline-block;
  margin-top: 41px;
  box-sizing: border-box; /* 让内边距计算在盒子宽度内 */
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease; /* 添加过渡效果 */
  box-shadow: 5px 5px 10px rgba(0, 3, 0, 0.3); /* 设置阴影效果 */
}
.all_left:hover {
  transform: scale(1.02); /* 鼠标经过时放大 1.1 倍 */
}

.all_left img {
  width: 588px;
  height: 353px;
  margin: 0;
}

.all_left_div {
  margin-left: 16px;
}

.all_left_text1 {
  color: #23337f;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 1px;
  overflow: hidden; /* 隐藏溢出文本 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 使用省略号 */
  padding-right: 20px;
}

.all_left_text2 {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 20px;
}

.all_left_text3 {
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* 设置最大行数为 2 */
  line-clamp: 2;
  box-sizing: border-box;
  padding-right: 20px;
}

.all_right {
  width: 588px;
  height: 541px;
  vertical-align: top;
  background: #f8f8f8;
  display: inline-block;
  margin-top: 41px;
  margin-left: 24px;
  padding: 20px, 20px; /* 设置内边距为 10px */
  box-sizing: border-box; /* 让内边距计算在盒子宽度内 */
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease; /* 添加过渡效果 */
  box-shadow: 5px 5px 10px rgba(0, 3, 0, 0.3); /* 设置阴影效果 */
}
.all_right:hover {
  transform: scale(1.02); /* 鼠标经过时放大 1.1 倍 */
}

.all_right_div1 {
  margin-top: 40px;
  margin-left: 40.12px;
}

.all_right_text1 {
  color: #444444;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 1px;
  overflow: hidden; /* 隐藏溢出文本 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 使用省略号 */
}

.all_right_text2 {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 20px;
}

.all_right_text3 {
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* 设置最大行数为 2 */
  line-clamp: 2;
  box-sizing: border-box;
  padding-right: 20px;
}

.all3 {
  width: 100%;
  height: 800px;
  /* background: thistle; */
  margin: 0 auto;
}

.all3_nav {
  height: 174px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.all3_nav_div1 {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  margin-top: 84px;
}

.all3_nav_div2 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-top: 98px;
}

.all3_div1 {
  width: 793px;
  height: 546px;
  display: inline-block;
  margin-left: 360px;
}

.all3_div1 > div:nth-child(2n) {
  margin-left: 25px;
}

.all3_div2 {
  height: 546px;
  width: 384px;
  display: inline-block;
  margin-left: 25px;
  position: relative;
}

.all3_img2 {
  height: 546px;
  width: 384px;
}

.all3_div1_img1 {
  width: 384px;
  position: absolute;
  left: 0px;
  height: 130px;
  top: 131px;
}

.all3_div1_p1 {
  width: 96px;
  height: 33px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 168px;
  left: 32px;
}

.all3_div1_p2 {
  width: 337px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: absolute;
  top: 211px;
  left: 32px;
}

.all3_div1_div {
  /* vertical-align: top; */
  display: inline-block;
  position: relative;
  margin-bottom: 24px;
  width: 384px;
  height: 261px;
  /* margin-right: 25px; */
}

.all3_img1 {
  width: 100%;
  height: 100%;
}

.all3_div2_p1 {
  width: 96px;
  height: 33px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
  position: absolute;
  top: 453px;
  left: 45px;
}

.all3_div2_p2 {
  width: 351px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: absolute;
  top: 496px;
  left: 45px;
}

.all3_div1_img2 {
  width: 384px;
  position: absolute;
  left: 0px;
  height: 261px;
  top: 285px;
  /* filter:opacity(75%); */
}

.all4 {
  width: 100%;
  height: 800px;
  background-image: url("../assets/homepage/bg2.png");
}

.all4_div1 {
  display: inline-block;
  vertical-align: top;
}

.all4_p1 {
  width: 160px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
  margin-left: 360px;
  padding-top: 143px;
  margin-top: 0;
}

.all4_p2 {
  /* background-color: darkkhaki; */
  width: 600px;
  height: 280px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  /* padding-top: 36px; */
  margin-left: 360px;
  letter-spacing: 1px;
}

.all4_img {
  margin-left: 113px;
  margin-top: 115px;
}

.all4_div2 {
  display: inline-block;
  vertical-align: top;
}

.all4_div3 {
  width: 160px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  margin-left: 360px;
}

.all4_div3_p {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin-left: 32px;
  margin-top: 9px;
}
.all4_div {
  position: relative;
  width: 1200px;
  background-color: red;
}
.all4_div4 {
  position: absolute;
  left: 360px;
  width: 180px;
  height: 8;
  margin-right: 144px;
}
.all4_div5 {
  position: absolute;
  left: 682px;
  width: 180px;
  height: 8;
  margin-right: 144px;
}
.all4_div6 {
  position: absolute;
  left: 989px;
  width: 180px;
  height: 8;
  margin-right: 144px;
}
.all4_div7 {
  position: absolute;
  left: 1383px;
  width: 180px;
  height: 8;
  margin-right: 144px;
}
.all4_div4_p1 {
  width: 77px;
  height: 84px;
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
  position: absolute;
  top: -48px;
}

.all4_div4_p2 {
  width: 12px;
  height: 24px;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  position: absolute;
  left: 95px;
}
.all4_div4_p3 {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  position: absolute;
  left: 95px;
  bottom: -107px;
}
.all4_div4_p4 {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  position: absolute;
  left: 162px;
  bottom: -60px;
}
.all4_div4_p5 {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  position: absolute;
  left: 162px;
  bottom: -107px;
}

.all5 {
  border: 1px dashed grey;
  width: 100%;
  height: 800px;
  position: relative;
  /* margin: 0 auto; */
}
.all5_div1 {
  width: 1200px;
  height: 212px;
  margin: 0 auto;
  /* border: #23337F 1px solid; */
}
.all5_div1_div1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  padding-top: 80px;
  /* margin-left: 888px; */
  margin: 0 auto;
}
.all5_div1_div2 {
  /* border: red 1px solid; */
  width: 1200px;
}
.all5_div1_div3 {
  width: 96px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 33px;
  display: inline-block;
  position: absolute;
  top: 181px;
  left: 883px;
}
.all5_div1_img1 {
  margin-left: 462px;
  margin-top: 42px;
  border: 1px dashed grey;
}

.all5_div1_img2 {
  margin-left: 512px;
  margin-top: 48px;
  /* border: 1px dashed grey; */
}
.all5_div1_div4 {
  display: inline-block;
  width: 96px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 33px;
  display: inline-block;
  position: absolute;
  top: 181px;
  /* left: 1099px; */
  margin-left: 50px;
}
.all5_div2 {
  margin-top: 72px;
  width: 1260px;
}
.all5_div2 li {
  border: 2px dashed #cfc9a8d1;
  margin-right: 17px;
  margin-bottom: 16px;
  width: 231px;
  height: 124px;
  display: inline-block;
}
.all5_div2 img {
  width: 100%;
  height: 100%;
}
</style>
