import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

// 设置请求根路径
axios.defaults.baseURL = '/api'
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  function(config) {
    // 从localStorage或其他方式获取token
    const token = sessionStorage.getItem('token');
    // 判断请求路径是否以admin/开头
    if (config.url.startsWith('admin/') && config.url !=='admin/login') {
      // 如果存在token，将其添加到请求头部中
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // 如果响应状态为 401，表示未授权访问，跳转到登录页面
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      router.push('/login'); // 修改成你的登录页面路由路径
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios

Vue.use(ElementUI);

Vue.config.productionTip = false
document.title = '肇庆佳利印染有限公司'




new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
