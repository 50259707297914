<template>
  <div class="div-nav2">
    <!-- <div class="div1"> -->
      <!-- <div class="div1_div">
        <li>企业概况</li>
        <li>集团产业</li>
        <li>企业新闻</li>
        <li>人力资源</li>
      </div>
      <div class="div1_div1">
        <li>关于企业</li>
        <li>企业实力</li>
        <li>发展历程</li>
        <li>企业荣誉</li>
      </div>
      <div class="div1_div2">
        <li>金融板块</li>
        <li>科技板块</li>
        <li>电商板块</li>
        <li>职教板块</li>
        <li>幼教板块</li>
      </div>
      <div class="div1_div3">
        <li>集团要闻</li>
        <li>公司新闻</li>
        <li>行业资讯</li>
      </div>
      <div class="div1_div4">
        <li>招聘信息</li>
      </div> -->
    <!-- </div> -->

    <div class="div2">
      <div class="div2_img">
        <img src="../../assets/homepage/logo(2).png">
      </div>
      <div class="div2_div1">
        <p>
          公司总部地址：广东省肇庆市高要区莲塘镇蚌岗工业园内<br>
          邮箱：jiali13702614268@163.com
        </p>
      
      </div>
    </div>
    <!-- <div class="div3">
      <img src="../../assets/homepage/位图.png" />
      <p>关注微信公众号</p>
    </div> -->
    <div class="line"></div>
  <div class="div4">
    <!-- <p>Copyright ©   xxxx有限公司         #网站备案号#</p> -->
  <span>Copyright © </span>
  <span class="span1">肇庆市佳利印染有限公司 </span>
  <span class="span2"> #网站备案号#</span>
  </div>
  </div>
</template>
<style scoped>
@import url("./bottomnav.css");
</style>
<script>
export default {
  name: "Bottom",
};
</script>